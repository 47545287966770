<template>
  <div>
    <p class="h3">Uploaded Giftcard</p>
    <div id="accordion">
      <div v-if="!pageloading" class="row">
        <div
          class="col-md-4 mb-3"
          size="height:14rem"
          v-for="(item, index) of category"
          :key="index"
        >
          <div class="p-0">
            <div class="card-header p-3" id="headingOne">
              <p class="mb-1 h6">Category Name : {{ item.categoryName }}</p>
              <p class="mb-1 h6">Details: {{ item.details }}</p>
              <p class="mb-1 h6">Rate: {{ item.rate }}</p>
              <h5 class="mb-0">
                <div class="d-flex justify-content-end">
                  <button
                    @click="currentEdit = item"
                    type="button"
                    class="btnd p-2 py-1 btn-success text-white"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    Edit
                  </button>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <Spinner v-if="pageloading" />
    </div>
    <!-- Button trigger modal -->
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="Edit">
              <div class="form-group">
                <label>Category Name</label>
                <input
                  type="text"
                  required
                  class="form-control"
                  v-model="currentEdit.categoryName"
                  placeholder="Enter Category Name"
                />
              </div>
              <div class="form-group">
                <label>Details</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="currentEdit.details"
                  required
                  aria-describedby="emailHelp"
                  placeholder="Enter Details"
                />
              </div>

              <button
                v-if="!loading"
                type="submit"
                class="btnd btn-success text-light"
              >
                Update
              </button>
              <button
                v-else
                class="btnd btn-success text-light"
                type="button"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btnd btn-success" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Calls from "@/Calls";
import Spinner from "@/components/Spinner";

export default {
  name: "AllSupport",
  components: { Spinner },
  data() {
    return {
      category: [],
      category1: [],
      currentEdit: {},
      pageloading: true,
      loading: false,
    };
  },
  methods: {
    async Edit() {
      this.loading = true;
      var message, type;
      await axios
        .put(
          `${Calls.baseUrl()}/admin/giftcard/category?giftcardId=${
            this.currentEdit.id
          }`,
          {
            categoryName: this.currentEdit.categoryName,
            details: this.currentEdit.details,
            // rate: this.currentEdit.rate,
          },
          { headers: JSON.parse(localStorage["headers"]) }
        )
        .then((res) => {
          type = "success";
          message = res.data.msg;
        })
        .catch((err) => {
          type = "error";
          message = err.response.data.msg;
        });

      this.$toast.open({
        message,
        type,
        position: "top-right",
        duration: 2000,
        pauseOnHover: true,
      });
      this.loading = false;
    },
  },
  async created() {
    await axios
      .get(`${Calls.baseUrl()}/giftcard/categories`, {
        headers: JSON.parse(localStorage["headers"]),
      })
      .then((res) => {
        this.category = res.data.data;
        // console.log(this.category);
      })
      .catch(() => {
        // this.$toast.open({
        //   message: err.response.data.msg,
        //   type: "error",
        //   position: "top-right",
        //   duration: 2000,
        //   pauseOnHover: true,
        // });
      });

    this.pageloading = false;
  },
};
</script>

<style scoped></style>
